export const AUTH_START = 'AUTH_START';
export const AUTH_SUCCESS = 'AUTH_SUCCESS';
export const AUTH_FAIL = 'AUTH_FAIL';
export const AUTH_LOGOUT = 'AUTH_LOGOUT';
export const AUTH_REGISTER = 'AUTH_REGISTER';
export const CLEAR_ERROR = 'CLEAR_ERROR';


export const UPDATE_WEEK_SCHED = 'UPDATE_WEEK_SCHED';
export const RENDER_WEEK_SCHED = 'RENDER_WEEK_SCHED';
export const UPDATE_NUM_DAYS = 'UPDATE_NUM_DAYS';
export const UPDATE_INIT_DAYS = 'UPDATE_INIT_DAYS';
export const UPDATE_WEEK_DATES = 'UPDATE_WEEK_DATES';
export const INCREMENT_PAGE_COUNT = 'INCREMENT_PAGE_COUNT';
export const DECREMENT_PAGE_COUNT = 'DECREMENT_PAGE_COUNT';
export const RESET_PAGE_COUNT = 'RESET_PAGE_COUNT';
export const FETCH_BOOK_START = 'FETCH_BOOK_START';
export const FETCH_BOOK_SUCCESS = 'FETCH_BOOK_SUCCESS';
export const UPDATE_SELECTED_SLOT = 'UPDATE_SELECTED_SLOT';
export const ADD_SELECTED_SLOT = 'ADD_SELECTED_SLOT';
export const UPDATE_CHECKOUT_BOOK = 'UPDATE_CHECKOUT_BOOK';
export const BOOK_START = 'BOOK_START';
export const BOOK_SUCCESS = 'BOOK_SUCCESS';
export const BOOK_FAILED = 'BOOK_FAILED';
export const RESET_STATE = 'RESET_STATE';
export const UPDATE_MY_BOOK = 'UPDATE_MY_BOOK';
export const FETCH_BOOK_DONE = 'FETCH_BOOK_DONE';

